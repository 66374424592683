import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { useInView } from 'react-intersection-observer'
import { useMount, useWindowSize } from 'react-use'
import { padStart } from 'lodash'
import Fade from 'react-reveal/Fade'

import { Layout, Slider, Footer, NewsSlider, ConditionalLink, FullPage } from '../components'
import { container, padding, bgImage, colours, type, textOffset, linedText, buttonStyles, bgIcon, hoverState } from '../styles/global'
import { parseACF } from '../utils/utils'
import { media, breakpoints } from '../styles/utils'

import ArrowRight from '../assets/images/arrow-right.inline.svg'

const Index = () => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const [fullPage, setFullPage] = useState(false)
    const [fixedHeadingActive, setFixedHeadingActive] = useState(false)
    const [activePageSection, setActivePageSection] = useState(0)
    const [isPhone, setIsPhone] = useState(false)
    const { width } = useWindowSize();

    const inViewOptions = {
        triggerOnce: true,
        rootMargin: '-50px 0px',
        threshold: 0.5,
    }

    useMount(() => {
		checkWidth()
    })
    
    useEffect(() => {
		checkWidth()
    }, [width])
    
    const checkWidth = () => {
		if (width <= breakpoints.phone) setIsPhone(true)
	}

    useEffect(() => {
        console.log(fullPage)
    }, [fullPage])

    const beforeSlideChange = (origin, destination, direction) => {        
        if (destination.index == 1 || destination.index == 2) {
            setFixedHeadingActive(true)
        } else {
            setFixedHeadingActive(false)
        }

        setActivePageSection(destination.index)
    } 

	const renderSlides = (slides) => {
		return slides.map((item, i) => {
			if (!item.image) return;

			return (
				<Slide
                    key={i}
				>
					<Image 
						image={item.image?.sizes?.full_width} 
					/>

					<Container>
						<SlideInfo>
							<LinedText
                            	className={'lined-text'}
                            >
								<Description
									dangerouslySetInnerHTML={{__html: item.description}}
								/>
							</LinedText>

                            <Subheading 
                                as={Link} 
                                to={`/case-studies/${item.project_slug}`}
                                className={'project-link'}
                            >
                                <span>
                                    {item.project_text}
                                </span>
                                <ArrowRight />
                            </Subheading>
						</SlideInfo>

                        <Counter 
                            className="counter"
                        >
                            <Subheading>
                                {padStart(i + 1, 2, '0')}
                            </Subheading>
                            
                            <Subheading>
                                <span>
                                    /{padStart(slides && slides.length, 2, '0')}
                                </span>
                            </Subheading>
                        </Counter>
					</Container>
				</Slide>
			)
		})
	}

	const renderHero = () => {
		if (!data.hero_slider) return;

		return (
			<Hero
                className={'section fp-auto-height'}
            >
				<Slider
					slides={data.hero_slider}
					renderSlides={renderSlides}
					sliderParams={{
						effect: 'fade',
						speed: 750,
                        // autoplay: {
                        //     delay: 5000,
                        //     disableOnInteraction: false
                        // },
					}}
					counter
					hoverCursors
				/>
			</Hero>
		)
    }
    
    const renderFixedHeading = () => {
        return (
            <FixedHeadingWrapper>
                <Container>
                    <FixedHeading
                        active={fixedHeadingActive}
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: data.ourstory_1_underlined_text,
                            }}
                        />
                    </FixedHeading>
                </Container>
            </FixedHeadingWrapper>
        )
    }
    
    const renderOurStory1 = () => {
        if (!data.ourstory_1_display) return;
        const [refPanel, inViewPanel] = useInView(inViewOptions)

        return (
            <Panel 
                ref={refPanel} 
                style={{
                    opacity: inViewPanel ? 1 : 0
                }}
                section={'our-story-1'}
                className={'section fp-auto-height'}
            >
                <PanelRow>
                    <PanelColumn>
                        <Heading>{data.ourstory_1_heading}</Heading>
                    </PanelColumn>

                    <PanelColumn>
                        <Subheading>
                            {data.ourstory_1_paragraph_sub_heading}
                        </Subheading>

                        <ParagraphIndent
                            dangerouslySetInnerHTML={{
                                __html: data.ourstory_1_paragraph_text,
                            }}
                        />

                        <StoryButtons>
                            {data.ourstory_1_links.map((item) => (
                                <StoryLink
                                    as={ConditionalLink}
                                    key={item.text}
                                    to={item.link}
                                >
                                    {item.text}
                                </StoryLink>
                            ))}
                        </StoryButtons>
                    </PanelColumn>
                </PanelRow>
            </Panel>
        )
    }
    
    const renderOurStory2 = () => {
        if (!data.ourstory_2_display) return;
        const [refPanel, inViewPanel] = useInView(inViewOptions)

        return (
            <Panel
                ref={refPanel} 
                style={{
                    opacity: inViewPanel ? 1 : 0
                }}
                section={'our-story-2'}
                className={'section fp-auto-height'}
            >
                <PanelRow reverse>
                    <PanelColumn>
                        <Subheading>
                            {data.ourstory_2_paragraph_sub_heading}
                        </Subheading>
                        
                        <ParagraphIndent
                            dangerouslySetInnerHTML={{
                                __html: data.ourstory_2_paragraph_text,
                            }}
                        />

                        <StoryButtons>
                            {data.ourstory_2_links.map((item) => (
                                <StoryLink
                                    as={ConditionalLink}
                                    key={item.text}
                                    to={item.link}
                                >
                                    {item.text}
                                </StoryLink>
                            ))}
                        </StoryButtons>
                    </PanelColumn>

                    <Fade up distance={'30px'} delay={600}>
                        <PanelColumn>
                            <ProgImage
                                key={data.ourstory_2_image.sizes?.medium2}
                                src={data.ourstory_2_image.sizes?.medium2}
                            >
                                {(src, loading) => {
                                    return (
                                        <BGImage
                                            image={src}
                                            style={{ opacity: loading ? 0 : 1 }}
                                        />
                                    )
                                }}
                            </ProgImage>
                        </PanelColumn>
                    </Fade>
                </PanelRow>
            </Panel>
        )
    }

    const renderPartnerships = () => {
        if (!data.partnerships_display) return
        const [refPanel, inViewPanel] = useInView(inViewOptions)
        
        return (
            <Partnerships
                className={'section fp-auto-height'}
            >
                <PartnershipsBG
                   active={activePageSection == 3}
                />
                <Panel 
                    ref={refPanel} 
                    inView={inViewPanel}
                    section={'partnerships'}
                >
                    <PanelRow>
                        <PanelColumn>
                            <Heading>{data.partnerships_heading}</Heading>
                        </PanelColumn>

                        <PanelColumn>
                            <Subheading>
                                {data.partnerships_sub_heading}
                            </Subheading>
                            <ParagraphIndent
                                dangerouslySetInnerHTML={{
                                    __html: data.partnerships_text,
                                }}
                            />
                        </PanelColumn>
                    </PanelRow>

                    <PanelRow>
                        <PartnershipGrid>
                            {data.partnerships_logo_grid.map(({ logo }) => (
                                <PartnershipLogo key={logo.url}>
                                    <img src={logo.url} />
                                </PartnershipLogo>
                            ))}
                        </PartnershipGrid>
                    </PanelRow>
                </Panel>
            </Partnerships>
        )
    }

    const renderNews = () => {
        if (!data.news_display) return
        const [refPanel, inViewPanel] = useInView(inViewOptions)

        return (
            <News
                className={'section fp-auto-height'}
            >
                <Panel 
                    ref={refPanel} 
                    style={{
                        opacity: inViewPanel ? 1 : 0
                    }}
                    section={'news'}
                >
                    <Container/>
                    <NewsSlider data={data} />
                </Panel>
            </News>
        )
    }
	
	return (
        <Layout 
            meta={data && data.seo} 
            logoTheme={activePageSection > 0 ? 'dark' : 'light'}
            hideByLine={activePageSection >= 1}
            disableFooter
        >
            <FullPage
                onApiChange={setFullPage}
                beforeSlideChange={beforeSlideChange}
            >
                {renderHero()}
                {isPhone && renderFixedHeading()}
                {renderOurStory1()}
                {renderOurStory2()}
                {renderPartnerships()}
                {renderNews()}

                <Footer
                    className={'section fp-auto-height'}
                />
            </FullPage>

            {!isPhone && renderFixedHeading()}
        </Layout>
    )
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3`
    font-size: 20px;

    ${media.desktopSmall`
        font-size: 16px;
    `}
`
const Description = styled.div``
const Info = styled.div``

const Image = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

const ProgImage = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
    height: 100%;
`

const LinedText = styled.div`
    ${linedText};
    color: ${colours.darkRed};
`

const ParagraphIndent = styled.p`
    ${textOffset};
    color: ${colours.midGray};
    max-width: 700px;

    &, p {
        margin-bottom: 0;
        margin-top: 0;
    }
`

// Layout

const Container = styled.div`
    ${container}
    ${padding}
`

// Fixed Heading

const FixedHeadingWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;

    display: flex;
    justify-content: center;
    pointer-events: none;

    ${media.phone`
        position: inherit;
    `}
`

const FixedHeading = styled(LinedText)`
    max-width: 700px;
    z-index: 10;
    margin-top: 140px;
    transition: opacity 250ms ease-in-out;
    opacity: 0;

    ${props => {
        if (props.active) return css`
            opacity: 1;
            transition: opacity 500ms 600ms ease-in-out;
        `
    }}

    ${media.desktopSmall`
        width: calc(50vw - 128px);
        margin-top: 96px;
    `}

    ${media.tablet`
        max-width: 463px;
        width: 100%;
    `}

    ${media.phone`
        opacity: 1;
        margin-top: 60px;
        margin-bottom: 0;
    `}
`

// Panel

const Panel = styled.div`
    ${container};
    ${padding};
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 140px;
    padding-bottom: 50px;
    transform: translate3d(0, ${(props) => (props.inView ? 20 : 0)}px, 0);
    transition: opacity 600ms ease-in-out, transform 900ms ease-out;
    will-change: opacity, transform;

    @supports (-webkit-touch-callout: none) {
        min-height: calc(100vh - 30px);
    }
    
    ${Heading} {
        ${type.heading2};
        font-size: 35px;
        color: ${colours.darkRed};
        margin-bottom: 20px;
        line-height: 1em;

        ${media.phone`
            margin-bottom: 40px;
        `}
    }

    ${media.phone`
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 45px;
        min-height: auto;
    `}

    .fp-tableCell {
        vertical-align: bottom;
    }

    /* border-bottom: 1px solid black; */

    /* Our Story 1 */
    
    ${props => {
        if (props.section == 'our-story-1') return css`
            justify-content: flex-end;
            padding-bottom: 50px;

            @media (max-height: 950px) {
                padding-bottom: 100px;
            }

            ${media.phone`
                padding-bottom: 0px;
            `}
        `
    }}

    /* Our Story 2 */

    ${props => {
        if (props.section == 'our-story-2') return css`
            justify-content: flex-end;

            ${BGImage} {
                padding-bottom: 55%;
                max-width: 333px;
                margin-left: auto;
            }

            ${media.phone`
                margin-top: 20px;

                ${BGImage} {
                    padding-bottom: 90%;
                    margin-bottom: 32px;
                    margin-left: none;
                }
            `}
        `
    }}

    /* News */

    ${props => {
        if (props.section == 'news') return css`
            justify-content: space-between;
            padding-left: 0;
            padding-right: 0;
            margin: 0;
            max-width: none;

            ${Container} {
                margin-bottom: auto;
            }           

            ${media.tablet`
                padding-left: 0;
                padding-right: 0;
            `}

            ${media.phone`
                padding-left: 0;
                padding-right: 0;
                justify-content: flex-start;
                margin-bottom: 130px;
                margin-top: 80px;

                ${Container} {
                    margin-bottom: 34px;

                    ${Heading} {
                        margin-bottom: 0;
                    }
                }           
            `} 
        `
    }}

`

const PanelRow = styled.div`

    display: flex;
    /* margin-bottom: 50px; */
    
    ${media.phone`
        flex-direction: column;
        margin-bottom: 0;

    ${(props) => {
        if (props.reverse)
            return css`
                flex-direction: column-reverse;
            `
        }}
    `}

    > div {
        width: 50%;
        margin-right: 40px;
        overflow: hidden;

        &:last-child {
            margin-right: 0;
        }

        ${media.phone`
            margin-right: 0;
            margin-bottom: 0;
            width: 100%;
        `}
    } 
`

const PanelColumn = styled.div`
    ${(props) => {
        if (props.isHeading)
            return css`
                margin-bottom: 50px;
                align-self: flex-end;
            `
    }}

    ${Subheading} {
        margin-bottom: 5px;
    }
`

// Story 

const StoryLink = styled.a`
    ${buttonStyles};
    padding-bottom: 4px;
`

const StoryButtons = styled.div`
    margin-top: 57px;

    ${media.phone`
        margin-top: 44px;
        margin-bottom: 34px;

        ${StoryLink} {
            margin-bottom: 16px;
        }
    `}
`

// Partnerships

const Partnerships = styled.section`
    position: relative;
    
    ${Panel} {
        padding-top: 64px;
    }

    ${media.phone`
        padding-top: 16px;

        ${Panel} {
            padding-top: 0;
            margin-top: 0;
        }
    `}
`

const PartnershipsBG = styled.div`
    transition: background-color 0.25s ease-in-out;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    ${props => {
        if (props.active) return css`
            transition: background-color 0.3s 0.6s ease-in-out;
            background-color: ${colours.offWhite};
        `
    }}

    ${media.phone`
        background-color: ${colours.offWhite};
    `}
`

const PartnershipGrid = styled.section`
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid ${colours.red};

    ${media.phone`
        margin-top: 60px;
        margin-bottom: 100px;
    `}
`

const PartnershipLogo = styled.div`
    width: 25%;
    text-align: center;
    border-bottom: 1px solid ${colours.red};

    ${media.phone`
        width: 50%;
    `}

    img {
        height: auto;
        width: 100%;
        max-width: 200px;
        transform: scale(1.5);

        ${media.tablet`
            /* padding: 23px; */
            margin: 15px 0px;
        `}

        ${media.phone`
            /* padding: 23px; */
            margin: 15px 0px;
        `}
    }
`

// News

const News = styled.div``

// Hero

const Hero = styled.div`
    width: 100%;

    .counter-wrapper {
        bottom: 8px;
        right: 50px;
        overflow-x: visible;

        ${media.tablet`
            right: 0;
            bottom: 0;
        `}

        ${media.phone`
            bottom: 0;

            .counter-wrapper {

            }
        `}
    }

    /* need to move the cursors out of the way so the anchor is clickable */
    .hover-cursors {
        bottom: 300px;
    }
`

// Hero Slider

const Slide = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 100vh;
	background: black;

    @supports (-webkit-touch-callout: none) {
        height: calc(100vh - 30px);
    }

    /* Min height for dev */
    
    /* &,
    ${Container} {
        min-height: 100vh;
    }  */
    
	${Image} {
		height: 100%;
		flex: 1;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
        opacity: 0.8;
	}

	${Container} {
		position: relative;
		height: 100%;
		margin-bottom: 50px;
        overflow: visible;

		${media.tablet`
			margin-bottom: 0;
		`}
	}

	/* Mobile Solid Footer */

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		height: 80px;
		background: ${colours.lightGray};
		display: none;

		${media.tablet`
			display: flex;
		`}
	}

	/* Info animation on slide change */

	.lined-text {
		opacity: 0;
		transform: translateY(20px);
	}

    .counter {
		opacity: 0;
		transform: translateY(20px);
	}

    .project-link {
		opacity: 0;
	}
	
	&.swiper-slide-active {
		.lined-text,
        .counter {
			opacity: 1;
			transform: translateY(0px);
            transition: all 0.75s 0.5s ease-out;
		}

        .project-link {
            opacity: 1;
            transition: opacity 1s 0.5s ease-out;
        }
	}

	${media.tablet`
		.lined-text,
        .counter {
			opacity: 1;
			transform: translateY(0px);
			transition: all 0.75s 0.5s ease-out;
		}


	`}
`

const SlideInfo = styled.div`
    position: absolute;
    bottom: 0;
    left: 100px;
    z-index: 2;
    max-width: 660px;

    ${media.tablet`
		left: 20px;
		right: 20px;
		max-width: 500px;
	`}

    ${LinedText} {
        > * {
            color: white;
        }

        ${media.phone`
			max-width: 100%;
		`}
    }

    ${Subheading} {
        ${type.body}
        color: white;
        margin-top: 55px;
        display: flex;
        align-items: center;

        ${media.tablet`
			height: 80px;
			display: flex;
			align-items: center;
			max-width: 70vw;
			color: ${colours.darkGray};
			line-height: 24px;
			margin-top: 20px;
        `}

        ${media.phone`
            span {
                max-width: 200px;
            }
        `}

        svg {
            color: #fff;
            margin-left: 5px;
            width: 30px;
            position: relative;
            transition: transform 0.25s ease;
            margin-top: -1px;

            ${media.tablet`
                color: ${colours.darkRed};
                width: 20px;
                margin-left: 10px;
                position: inherit;
            `}
        }

        &:hover {
            svg {
                transform: translateX(4px);
            }
        }
    }
`

// Slide Counter

const Arrow = styled.div``

const Counter = styled.div`
	position: absolute;
	bottom: 0;
	right: 100px;
	z-index: 2;
	display: flex;
	align-items: center;

	${Subheading} {
		${type.heading3}
		color: white;

		span { 
			color: ${colours.red};
            visibility: hidden;
		}

		${media.tablet`
			color: ${colours.darkGray};

			span {
				color: ${colours.darkRed}
			}
		`}
	}

	${Arrow} {
		background-image: url(${require('../assets/images/arrow-right.svg')});
		width: 19px;
		height: 16px;
		margin-left: 8px;
		${bgIcon}
        ${hoverState}
        display: none;
        
        ${media.tablet`
            display: block;
        `}
	}

	${media.tablet`
		bottom: 20px;
		right: 20px;

		${Subheading} {
			font-size: 18px;
		}

		${Arrow} {
			display: none;
		}
	`}

	${media.phone`
		bottom: 0;
        height: 80px;
	`}
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
